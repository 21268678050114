import NoticePublishRangePage from '@/views/NoticePublishRange/index.vue'
export default {
	name: 'NoticePublishPage',
	components: {
		NoticePublishRangePage
	},
	data() {
		return {
			// 表单数据
			submitData: {
				title: '',
				description: '',
				notifyall: false,
				noticescope: []
			},
			// 校验规则
			submitRules: {
				title: [
					{ required: true, message: this.$t('noticePublish.noticeTitleTips'), trigger: 'blur' },
					{ message: this.$t('noticePublish.noticeTitleOver'), trigger: 'blur', max: 50 },
				],
				description: [
					{ required: true, message: this.$t('noticePublish.noticeContentTips'), trigger: 'blur' },
					{ message: this.$t('noticePublish.noticeContentOver'), trigger: 'blur', max: 300 },
				],
			},
			// 选择范围弹窗
			drawerVisible: false
		}
	},
	methods: {
		// 提交
		addnotice(formName) {

			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.submitData.noticescope.length == 0 && this.submitData.notifyall == false) {
						// showDialog(_this2.pageText.PROMPT, _this2.pageText.CHECKSELNOTICEPER, "warn", "", [_this2.pageText.OK]);
						this.$alert(this.$t('noticePublish.checkSelNoticeper'), this.$t('common.alertPrompt'), {
							type: 'error',
							center: true
						})

					} else {
						let noticescope = []
						this.submitData.noticescope.forEach((item) => {
							noticescope.push({ hrid: item.hrid })
						})
						this.$http.post('/notification/addnotify', {
							hrid: this.$store.getters.loginid,
							penum: this.$store.getters.penum,
							"title": this.submitData.title,
							"description": this.submitData.description,
							"timing": '',
							"notifyall": this.submitData.notifyall ? "1" : "0",
							"noticescope": noticescope
						}).then((response) => {
							if (response.data.success) {
								// _this2.canceladd();
								// _this2.getAllData();
								this.$message({
									type: 'success',
									message: this.$t('noticePublish.publishSuccess')
								});
								this.$emit('createSuccess')
							} else {
								this.$alert(response.data.message, this.$t('common.alertPrompt'), {
									type: 'error',
									center: true
								})
							}
						})
					}
				}
			});
		},
		// 选择人员范围
		selectRange() {
			if (!this.submitData.notifyall) {
				this.drawerVisible = true
			}
		},
		// 选择发送人
		confirmSelect(data) {
			this.submitData.noticescope = data
			this.closeSelectRange()
		},
		// 关闭选择人员范围
		closeSelectRange() {
			this.drawerVisible = false
		},
	}
}