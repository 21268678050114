<template>
  <el-container class="container">
    <el-main class="main-wrapper">
      <el-form :model="submitData" :rules="submitRules" ref="createForm">
        <el-form-item label="" prop="title">
          <el-input :placeholder="$t('noticePublish.noticeTitlePlaceholder')" v-model="submitData.title" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="" prop="description">
          <el-input :placeholder="$t('noticePublish.noticeContentPlaceholder')" v-model="submitData.description" :rows="8" type="textarea" maxlength="300" show-word-limit></el-input>
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer class="footer-wrapper" height="auto">
      <el-row>
        <el-col :span="8" class="switch-button">
          <el-switch v-model="submitData.notifyall" :active-text="$t('noticePublish.notifyAll')"> </el-switch>
        </el-col>
        <el-col :span="10">
          <div class="range-button" :class="{ disabled: submitData.notifyall }" @click="selectRange"><i class="el-icon-s-operation"></i>{{ $t('noticePublish.setNoticeRange') }}</div>
        </el-col>
        <el-col :span="6" style="text-align: right">
          <el-button type="primary" @click="addnotice('createForm')">{{ $t('common.publish') }}</el-button>
        </el-col>
      </el-row>

      <!-- 选择范围 -->
      <el-drawer :title="$t('noticePublishRange.dialogNotifyAllTitle')" append-to-body custom-class="drawer-wrapper" :destroy-on-close="true" :visible.sync="drawerVisible">
        <notice-publish-range-page v-if="drawerVisible" :editData="submitData.noticescope" @confirmSelect="confirmSelect" @closeSelectRange="closeSelectRange"></notice-publish-range-page>
      </el-drawer>
    </el-footer>
  </el-container>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
