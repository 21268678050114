
export default {
  name: 'NoticeReadPage',
  props: {
    viewData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // 通知读取列表数据
      getreadinglist: [],
      // 翻页配置
      pagesObj: {
        page: 1,
        pagesize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getReadingData()
    })



  },
  methods: {
    //通知读取列表
    getReadingData(page) {
      if (page) {
        this.pagesObj.page = page
      } else {
        this.pagesObj.page = 1
      }
      this.$http.post('/notification/reading', {
        hrid: this.$store.getters.loginid,
        penum: this.$store.getters.penum,
        "query": this.query,
        page: this.pagesObj.page,
        pagesize: this.pagesObj.pagesize,
        notifynum: this.viewData.notifynum,
      }, {
        loadTarget: this.$refs.tableWrapper
      }).then(response => {
        // 更新数据
        if (response.data.success) {
          this.getreadinglist = response.data.content;
          this.pagesObj = {
            ...this.pagesObj,
            // page: response.data.page,
            total: response.data.total
          }

        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
  }
}